@import '../../../styles/base-refs';

.advisorsSection {
  padding: 0px 24px 160px;

  @include mobile {
    padding: 0px 24px 60px;
  }

  h2 {
    margin: 0 auto 48px;
    max-width: 787px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0px;
    color: #ffffff;

    @include mobile {
      margin-bottom: 24px;
      font-size: 24px;
    }
  }

  .advisors {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    max-width: 1114px;
    margin: 56px auto 0;

    @include mobile {
      flex-direction: column;
    }
  }

  .advisor {
    position: relative;
    width: 50%;
    max-width: 360px;
    padding: 28px 33px;
    background-color: #fff;
    box-shadow: 0px 50px 99px #1f485d1a;

    @include tablet {
      padding: 20px;
    }

    @include mobile {
      width: 100%;
      padding: 20px;
    }

    p {
      margin: 0;
      text-align: left;
      font-size: 16px;
      letter-spacing: 0px;
      line-height: 1.625;
      color: #b1aaaa;

      &.title {
        font-size: 14px;
        font-weight: 300;
        color: #b1aaaa;

        span {
          font-weight: 400;
          color: #1e1c24;
        }
      }
    }
  }

  .personRow {
    display: flex;
    align-items: center;
    gap: 18px;

    @include mobile {
      width: calc(100% - 40px);
    }

    img {
      width: 54px;
      height: 54px;
      max-width: 54px;
    }

    p {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0px;
      color: #1e1c24;
    }
  }

  .underline {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 7px;
    width: 100%;
    background: #8350d2 0% 0% no-repeat padding-box;
  }
}
