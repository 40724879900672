@import '../../../styles/base-refs';

.tokenSection {
  padding: 60px 24px 140px;

  @include mobile {
    padding: 20px 24px 60px;
  }

  h2 {
    margin: 0 auto;
    text-align: center;
    font-size: 42px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0px;
    color: #ffffff;

    @include mobile {
      margin-bottom: 24px;
      font-size: 24px;
    }

    strong {
      font-weight: 800;
    }
  }

  h3 {
    margin: 0 auto 48px;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: center;

    @include mobile {
      margin-bottom: 24px;
      font-size: 18px;
    }
  }

  h4 {
    margin: 0 auto 4px;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: center;

    @include mobile {
      font-size: 16px;
    }
  }

  h5 {
    margin: 0 auto 24px;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: center;

    @include mobile {
      font-size: 14px;
    }
  }

  ul {
    margin: 12px 0 0;
    list-style: none;

    @include mobile {
      padding-left: 10px;
    }
  }

  li {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.458;
    letter-spacing: 0px;
    color: #d0d0d0;

    > ul {
      margin: 0;
    }

    @include mobile {
      margin-bottom: 8px;
      font-size: 16px;
    }

    &:before {
      content: '• ';
    }
  }

  .tokenRow {
    display: flex;
    gap: 24px;
    margin: 0 auto;
    max-width: 1200px;

    @include mobile {
      flex-direction: column;
    }
  }
}
