@import '../../../styles/base-refs';

.benefitsSection {
  padding: 140px 24px;
  text-align: center;

  @include mobile {
    padding: 60px 24px;
  }

  h2 {
    margin: 0 auto;
    max-width: 787px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0px;
    color: #ffffff;

    @include mobile {
      font-size: 24px;
    }
  }

  .benefits {
    display: flex;
    flex-wrap: wrap;
    column-gap: 90px;
    row-gap: 60px;
    max-width: 1200px;
    margin: 90px auto 0;

    @include mobile {
      margin-top: 36px;
      row-gap: 24px;
    }

    .benefit {
      width: calc(33.333333% - 60px);

      @include mobile {
        width: 100%;
      }

      h3 {
        margin: 12px 0;
        font-size: 25px;
        font-weight: 800;
        letter-spacing: 0px;
        color: #ffffff;

        @include mobile {
          font-size: 18px;
        }
      }

      p {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 0px;
        color: #e6e6e6;

        @include mobile {
          font-size: 14px;
        }
      }
    }
  }
}
