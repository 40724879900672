$medium-desktop: 1360px;
$small-desktop: 1220px;
$tablet-std: 1024px;
$tablet: 960px;
$mobile: 767px;

@mixin medium-desktop {
  @media (max-width: $medium-desktop) {
    @content;
  }
}

@mixin small-desktop {
  @media (max-width: $small-desktop) {
    @content;
  }
}

@mixin tablet-std {
  @media (max-width: $tablet-std) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}

@mixin mobile-min {
  @media (min-width: ($mobile + 1)) {
    @content;
  }
}
