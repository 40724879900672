@import '../../../styles/base-refs';

.interfaceSection {
  max-width: 1600px;
  margin: 0 auto;
  padding: 60px 24px;
  text-align: center;

  @include mobile {
    padding: 60px 24px;
  }

  h2 {
    margin: 0 auto 48px;
    max-width: 787px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0px;
    color: #ffffff;

    @include mobile {
      font-size: 32px;
    }
  }

  p {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.458;
    letter-spacing: 0px;
    text-align: left;
    color: #d0d0d0;

    @include small-desktop {
      font-size: 24px;
    }

    @include mobile {
      font-size: 16px;
    }
  }

  .interfaceRow {
    display: flex;
    gap: 36px;

    @include small-desktop {
      align-items: center;
    }

    @include mobile {
      gap: 12px;
      flex-direction: column;
    }

    .interfaceCol,
    .interfaceColImg {
      width: 50%;

      @include mobile {
        width: 100%;
      }
    }

    .interfaceColImg {
      text-align: center;

      @include small-desktop {
        width: 60%;
      }

      @include mobile {
        width: 100%;
      }
    }

    img {
      width: 100%;

      @include mobile {
        width: 80%;
      }
    }
  }
}
