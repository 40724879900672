@import '../../../styles/base-refs';

.footer {
  position: relative;
  padding: 160px 24px;
  overflow: hidden;
  text-align: center;
  background-color: #fff;

  h2 {
    margin: 0;
    font-weight: bold;
    font-size: 50px;
    line-height: 1.2;
    letter-spacing: 0px;
    color: #1e1c24;

    @include mobile {
      font-size: 24px;
    }
  }

  p {
    max-width: 670px;
    margin: 12px auto 0;
    font-size: 27px;
    font-weight: 300;
    line-height: 1.296;
    letter-spacing: 0px;
    color: #1e1c24;

    @include mobile {
      font-size: 16px;
    }

    &.calltoaction {
      margin-top: 32px;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .circle {
    position: absolute;
    right: -630px;
    bottom: -160px;
    height: 930px;
  }

  .triangle {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;

    @include mobile {
      display: none;
    }
  }

  .socials {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 16px;
  }

  a {
    display: block;
    color: black;

    &:hover {
      color: black;
    }
  }
}
