@import '../../../styles/base-refs';

.hero {
  position: relative;
  padding: 60px 120px 0;

  @include small-desktop {
    padding: 60px 80px 0;
  }

  @include mobile {
    padding: 20px 24px 0;
    text-align: center;
  }

  .logo {
    width: 400px;

    @include small-desktop {
      width: 300px;
    }
  }

  h1 {
    margin: 120px 0 0;
    font-size: 56px;
    font-weight: 800;
    line-height: 1.1351;
    letter-spacing: -0.53px;
    color: #ffffff;

    @include small-desktop {
      max-width: 500px;
      font-size: 48px;
    }

    @include tablet {
      font-size: 42px;
    }

    @include mobile {
      margin: 64px auto 0;
      font-size: 32px;
    }
  }

  .copy {
    position: relative;
    max-width: 760px;
    z-index: 10;

    @media (max-width: 2000px) {
      max-width: 680px;
    }

    @include small-desktop {
      max-width: 480px;
    }

    @include mobile {
      margin: 0 auto;
    }

    p {
      margin: 16px 0 0;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.333333;
      letter-spacing: 0px;
      color: #d0d0d0;

      @include small-desktop {
        font-size: 20px;
      }

      @include tablet {
        font-size: 16px;
      }

      @include mobile {
        font-size: 16px;
      }
    }
  }

  .broughtToYou {
    margin-top: 60px;

    @include mobile {
      margin-top: 32px;
    }

    p {
      margin: 0 0 12px;
      font-size: 24px;
      font-weight: 300;
      line-height: 35px;
      letter-spacing: 0px;
      color: #d0d0d0;

      @include mobile {
        font-size: 16px;
      }
    }

    img {
      width: 359px;

      @include small-desktop {
        width: 240px;
      }

      @include mobile {
        width: 200px;
      }
    }
  }
}

.graphs {
  position: absolute;
  top: 0;
  right: -2%;
  width: 50%;
  max-width: 950px;

  @include medium-desktop {
    top: 3%;
    right: -7%;
  }

  @include mobile {
    display: none;
  }
}
