@import '../../styles/base-refs';

.homePage {
  position: relative;
  background-image: url('./images/big-circles.svg');
  background-position: right -1700px top -900px;
  background-repeat: no-repeat;
  background-size: 2200px;
  overflow: hidden;

  @include small-desktop {
    background-position: right -1770px top -1140px;
  }

  @include tablet {
    background-position: right -1900px top -1175px;
  }

  @include mobile {
    background-image: none;
  }
}
